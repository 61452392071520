var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "app flex-row align-items-center" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-row",
          { staticClass: "justify-content-center" },
          [
            _c(
              "b-col",
              { attrs: { md: "4" } },
              [
                _c(
                  "b-card-group",
                  [
                    _c(
                      "b-card",
                      { staticClass: "p-3", attrs: { "no-body": "" } },
                      [_c("router-view")],
                      1
                    ),
                  ],
                  1
                ),
                _c("b-card-group", { staticClass: "p-4 copyright" }, [
                  _vm._v(" © " + _vm._s(_vm.getYear) + " "),
                  _c("span", { staticClass: "change-icon" }, [
                    _c("i", { staticClass: "fa fa-rebel" }),
                    _c("i", { staticClass: "fa fa-empire" }),
                  ]),
                  _vm._v(" Kovi "),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }