<script>
export default {
  name: 'Auth',
  computed: {
    getYear() {
      return new Date().getFullYear();
    },
  }
}
</script>

<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="4">
          <b-card-group>
            <b-card no-body class="p-3">
              <router-view />
            </b-card>
          </b-card-group>
          <b-card-group class="p-4 copyright">
            &copy; {{ getYear }}
            <span class="change-icon">
              <i class="fa fa-rebel" />
              <i class="fa fa-empire" />
            </span>
            Kovi
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card {
    /* background-color: rgba(255, 255, 255, 0.7); */
    border: none;
    width: 600px;
    height: 260px;
    padding: 0;
    background-image: url(/img/spinner.gif);
    background-size: contain;
    background-repeat: no-repeat;
}
.navbar-brand-full {
    margin-left: -4px;
    margin-bottom: 10px;
}
.copyright {
    text-align: center !important;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    display: block;
}
</style>
